<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Add User</div>
    </div>
    <div class="search-voter-section-outer" style="height: calc(100vh - 141px);">
        <div class="layout-content-section">
            <div class="form-box-outer">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                            <label class="form-label">Mobile No.<span class="text-danger">*</span></label>
                            <div class="input-group custom-input-group">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <Multiselect v-model="countrycode" :options="countryCodeList" :searchable="true"
                                        label="label" placeholder="Select" disabled
                                        class="multiselect-custom custom-title-multiselect" :canClear="false"
                                        :closeOnSelect="true" :object="true" noOptionsText="No Result"
                                        :canDeselect="false" />
                                </div>
                                <div class="col-lg-8 col-md-8 col-12">
                                    <input type="text" v-on:keypress="onchangesearchmobile()"
                                        :disabled="disabledinput || btnloader" v-model="searchmobile"
                                        class="form-control" id="formMobileNoInput" placeholder="Enter mobile No."
                                        maxlength="10" @paste="onPasteMobile" @keypress="onlyNumberMobile"
                                        autocomplete="off" />
                                </div>
                            </div>
                            <span class="custom-error" v-if="mobilealertmsg">
                                {{ mobilealertmsg }}
                            </span>
                        </div>
                    </div>
                    <!-- <div class="col-lg-1 col-md-1 col-12">
                        <div class="customer-label-group text-center" style="margin-top: 35px;">
                            <label class="from-label-value">-- OR --</label>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                            <label class="form-label">System Voter No.<span class="text-danger">*</span></label>
                            <input type="text" v-on:keypress="onchangesearchsystemno()"
                                :disabled="disabledinput || btnloader" v-model="searchuniqueid" class="form-control"
                                placeholder="Enter System Voter No." maxlength="10" @keypress="onlyNumber"
                                autocomplete="off" />
                        </div>
                    </div> -->
                    <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group" style="margin-top: 26px;">
                            <label class="form-label"></label>
                            <button type="button" class="header-btn-outer btn btn-primary" :style="{ width: '100px' }"
                                @click="searchvoterbtn(searchmobile, searchuniqueid)"
                                :disabled="searchmobile.length != 10 && searchuniqueid == '' || disabledinput || btnloader">
                                <span v-if="!btnloader">Search</span>
                                <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="btnloader">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="col text-end">
                        <button type="button" v-if="disabledinput" style="margin-top: 26px;"
                            class="clear-addvoter-btn btn btn-primary" @click="clearall()">
                            Clear All
                        </button>
                        <!-- <button v-if="this.addmorebtncount > 1 && this.addmorebtncount < 7" type="button" style="margin-top: 26px;"
                          class="clear-addvoter-btn btn btn-primary" @click="createUserModalOpen()">
                          Add More User
                        </button> -->
                    </div>
                </div>
                <div class="row" v-for="msg in searchMobileResult" :key="msg">
                    <div class="col-lg-12 col-md-12 col-12" v-if="msg.ak29 == 3">
                        <div class="voter-already-user-status">
                            <i class="pi pi-info-circle"></i> +91 {{ msg.ak24 }} <span>this number has already registered as a User in the system.</span>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12" v-if="msg.ak29 == 2">
                        <div class="voter-already-user-status">
                            <i class="pi pi-info-circle"></i> +91 {{ msg.ak24 }} <span>this number has already registered as a Karyakartas in the system.</span>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="voter-exit-box-outer mb-3">
                            <div class="row">
                                <div class="
                          col-lg-2 col-md-2 col-12
                          d-flex
                          align-items-center
                        ">
                                    <div class="customer-label-group">
                                        <div class="d-flex align-items-center">
                                            <div :class="msg.ak29 == 3
                                        ? 'border-voter-user' : msg.ak29 == 2 ? 'border-voter-karyakartas'
                                            : 'border-voter-only'
                                        ">
                                                <img v-if="(msg.ak90 == null || msg.ak90 == '') && (msg.ak33 == null || msg.ak33 == '')"
                                                    src="/assets/images/profile.svg" class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                    " alt="" style="width: 72px; height: 72px;" />
                                                <img v-if="msg.ak90 != null && msg.ak90 != ''"
                                                    :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/voterphoto/' + msg.ak90"
                                                    class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                      voter-photo-img
                                    " alt="" />
                                                <img v-if="(msg.ak33 != null && msg.ak33 != '') && (msg.ak90 == null || msg.ak90 == 'NULL' || msg.ak90 == '')"
                                                    :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + msg.ak33"
                                                    class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                      voter-photo-img
                                    " alt="" />
                                                <img v-if="msg.ak29 == 1" src="/assets/images/icon/voters-tab-icon.svg"
                                                    alt="" title="Voter" width="16" />
                                                <img v-if="msg.ak29 == 2" src="/assets/images/icon/users-tab-icon.svg"
                                                    alt="" title="Karyakartas" width="16" />
                                                <img v-if="msg.ak29 == 3"
                                                    src="/assets/images/icon/customers-tab-icon.svg" alt="" title="User"
                                                    width="16" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Name</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ msg.ak5 ? msg.ak5 : "N/A" }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Mobile No.</label>
                                                <!-- <div class="from-label-value">
                                                    +91-{{ msg.ak24 }}
                                                </div> -->
                                                <div v-if="msg.ak29 == 3">
                                                    <div class="from-label-value" v-if="this.allowshowusermobile == 0">
                                                        +91-{{ msg.ak24 ? maskedNumber(msg.ak24) : "N/A" }}
                                                    </div>
                                                    <div class="from-label-value" v-if="this.allowshowusermobile == 1">
                                                        +91-{{ msg.ak24 ? msg.ak24 : "N/A" }}
                                                    </div>
                                                </div>
                                                <div v-if="msg.ak29 == 2">
                                                    <div class="from-label-value"
                                                        v-if="this.allowshowmobilekaryakartasfk == 0">
                                                        +91-{{ msg.ak24 ? maskedNumber(msg.ak24) : "N/A" }}
                                                    </div>
                                                    <div class="from-label-value"
                                                        v-if="this.allowshowmobilekaryakartasfk == 1">
                                                        +91-{{ msg.ak24 ? msg.ak24 : "N/A" }}
                                                    </div>
                                                </div>
                                                <div v-if="msg.ak29 == 1">
                                                    <div class="from-label-value"
                                                        v-if="this.allowshowmobilevoterfk == 0">
                                                        +91-{{ msg.ak24 ? maskedNumber(msg.ak24) : "N/A" }}
                                                    </div>
                                                    <div class="from-label-value"
                                                        v-if="this.allowshowmobilevoterfk == 1">
                                                        +91-{{ msg.ak24 ? msg.ak24 : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Lok Sabha</label>
                                                <div class="from-label-value">
                                                    {{ msg.ak63 ? msg.ak63 : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Email Id</label>
                                                <div v-if="msg.ak29 == 3">
                                                    <div class="from-label-value" v-if="this.allowshowemailuserfk == 0">
                                                        {{ msg.ak23 ? maskedEmail(msg.ak23) : "N/A" }}
                                                    </div>
                                                    <div class="from-label-value" v-if="this.allowshowemailuserfk == 1">
                                                        {{ msg.ak23 ? msg.ak23 : "N/A" }}
                                                    </div>
                                                </div>
                                                <div v-if="msg.ak29 == 2">
                                                    <div class="from-label-value"
                                                        v-if="this.allowshowemailkaryakartasfk == 0">
                                                        {{ msg.ak23 ? maskedEmail(msg.ak23) : "N/A" }}
                                                    </div>
                                                    <div class="from-label-value"
                                                        v-if="this.allowshowemailkaryakartasfk == 1">
                                                        {{ msg.ak23 ? msg.ak23 : "N/A" }}
                                                    </div>
                                                </div>
                                                <div v-if="msg.ak29 == 1">
                                                    <div class="from-label-value"
                                                        v-if="this.allowshowemailvoterfk == 0">
                                                        {{ msg.ak23 ? maskedEmail(msg.ak23) : "N/A" }}
                                                    </div>
                                                    <div class="from-label-value"
                                                        v-if="this.allowshowemailvoterfk == 1">
                                                        {{ msg.ak23 ? msg.ak23 : "N/A" }}
                                                    </div>
                                                </div>
                                                <!-- <div class="from-label-value">
                                                    {{ msg.ak23 ? msg.ak23 : "N/A" }}
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Birth Date</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ format_date(msg.ak18) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Vidhan Sabha</label>
                                                <div class="from-label-value">
                                                    {{ msg.ak61 ? msg.ak61 : "N/A" }}<span class="ms-1"
                                                        v-if="msg.ab23">({{
                                        msg.ab23 }})</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 col-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Gender</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{
                                        msg.ak22
                                            ? msg.ak22 == 1
                                                ? "Male"
                                                : msg.ak22 == 2
                                                    ? "Female"
                                                    : "Others"
                                            : "N/A"
                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Designation</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ msg.ak84 ? msg.ak84 : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-1 col-md-1 col-12"
                                    v-if="msg.ak29 == 1 && this.alloweditvoterfk == 1">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12 text-end">
                                            <button type="button" title="Edit Details"
                                                class="btn custom-outline-view-btn" @click="createUserModalOpen(msg)">
                                                <i class="pi pi-pencil call-btn-color"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- search voter alert modal start here -->
    <div class="modal-mask" v-if="usersearchalertmodals">
        <div class="
        modal-dialog modal-xs modal-dialog-scrollable modal-dialog-centered
        custom-modal-outer
      ">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Alert</h5>
                    <button type="button" class="btn-close" @click="closeSearchAlertModal()"></button>
                </div>
                <div class="modal-body text-center alert-body-bg">
                    <div class="voter-alert-group">
                        <div class="mb-4">+91 {{ this.ak24 }} Mobile Number is not Registered as a Voter,
                            Click on "Create" button to add a new Voter.</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 text-center">
                        <button type="button" class="btn modal-bulk-next-btn" @click="registervotermodalopen(this.ak24)"
                            :disabled="createuserloader">
                            <span v-if="!createuserloader">Create</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="createuserloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div> 
                </div>
            </div>
        </div>
    </div>
    <!-- search voter alert modal end here -->
    <!-- Create Mobile No Via Voter Detail start here -->
    <div class="modal-mask" v-if="createvoterdetailstatus">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header custom-custmer-header-border">
                    <h5 class="modal-title" id="staticBackdropLabel">Create User</h5>
                    <button type="button" class="btn-close" @click="voterRegistrationModalClose()"></button>
                </div>
                <div class="px-4 pt-2">
                    <div class="stepper-label-edit-voter">
                        <span v-if="voterAddStepAadharStatus">Step<span
                                class="stepper-label-circle-voter">1</span></span>
                        <span v-if="voterAddStepVoterStatus">Step<span
                                class="stepper-label-circle-voter">2</span></span>
                        <span v-if="voterAddStepFirstStatus">Step<span
                                class="stepper-label-circle-voter">3</span></span>
                        <span v-if="voterAddStepSecondStatus">Step<span
                                class="stepper-label-circle-voter">4</span></span>
                        <span v-if="voterAddStepThirdStatus">Step<span
                                class="stepper-label-circle-voter">5</span></span>
                    </div>
                </div>
                <Divider />
                <!-- Create Voter Stepper First start here -->
                <div class="modal-body modal-body-scroll-outer pt-3" v-if="voterAddStepAadharStatus">
                    <div style="position: relative;
    min-height: 397px;">
                        <div v-if="!showloaderaadharmodal">
                            <div v-if="aadhardivdisplayflag == 1">
                                <div class="row">
                                    <div class="
                                    col-lg-2 col-md-2 col-12
                                    d-flex
                                    align-items-center
                                    ">
                                        <div class="customer-label-group">
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <img v-if="AadharTabDetails.ak33 == null ||
                                        AadharTabDetails.ak33 == ''
                                        " src="/assets/images/profile.svg"
                                                        class="rounded bg-light me-auto d-block" alt="" width="90" />
                                                    <img :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + AadharTabDetails.ak33"
                                                        class="rounded bg-light me-auto d-block" alt="" width="90" v-if="AadharTabDetails.ak33 != null &&
                                        AadharTabDetails.ak33 != ''
                                        " />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Name</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                        AadharTabDetails.ak5
                                            ? AadharTabDetails.ak5
                                            : "N/A"
                                    }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Aadhar Number</label>
                                                    <div class="from-label-value">
                                                        {{ maskedNumber(AadharTabDetails.ak36) ?
                                        maskedNumber(AadharTabDetails.ak36) : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12 text-end" v-if="aadharupdatebtnflag">
                                        <button type="button"
                                            @click="confirmAadharUpdateModalOpen(AadharTabDetails.aa3)"
                                            class="btn custom-view-detail-btn" :disabled="showupdateloader"
                                            :style="{ width: '70px' }">
                                            <span v-if="!showupdateloader">Update</span>
                                            <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                                                v-if="showupdateloader">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12 text-end" v-if="aadharUpdateBtnDaysMsg">
                                        <div class="col-12">
                                            <div class="custom-img-error">{{ this.aadharUpdateBtnDaysMsg }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Date of Birth</label>
                                            <div class="from-label-value">
                                                {{ format_date(AadharTabDetails.ak18) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Gender</label>
                                            <div class="from-label-value">
                                                {{
                                        AadharTabDetails.ak22
                                            ? AadharTabDetails.ak22 == 1
                                                ? "Male"
                                                : AadharTabDetails.ak22 == 2
                                                    ? "Female"
                                                    : "Others"
                                            : "N/A"
                                    }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">House</label>
                                            <div class="from-label-value text-capitalize">
                                                {{
                                            AadharTabDetails.ak148
                                                ? AadharTabDetails.ak148
                                                : "N/A"
                                        }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Landmark</label>
                                            <div class="from-label-value text-capitalize">
                                                {{
                                            AadharTabDetails.ak50
                                                ? AadharTabDetails.ak50
                                                : "N/A"
                                        }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Street</label>
                                            <div class="from-label-value text-capitalize">
                                                {{
                                            AadharTabDetails.ak147
                                                ? AadharTabDetails.ak147
                                                : "N/A"
                                        }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Location</label>
                                            <div class="from-label-value text-capitalize">
                                                N/A
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Post Office</label>
                                            <div class="from-label-value text-capitalize">
                                                {{
                                            AadharTabDetails.ak92
                                                ? AadharTabDetails.ak92
                                                : "N/A"
                                        }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Area</label>
                                            <div class="from-label-value text-capitalize">
                                                {{
                                            AadharTabDetails.ak44
                                                ? AadharTabDetails.ak44
                                                : "N/A"
                                        }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">City</label>
                                            <div class="from-label-value text-capitalize">
                                                {{
                                            AadharTabDetails.ak46
                                                ? AadharTabDetails.ak46
                                                : "N/A"
                                        }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Sub Dist</label>
                                            <div class="from-label-value text-capitalize">
                                                {{
                                            AadharTabDetails.ak146
                                                ? AadharTabDetails.ak146
                                                : "N/A"
                                        }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">State</label>
                                            <div class="from-label-value text-capitalize">
                                                {{
                                            AadharTabDetails.ak48
                                                ? AadharTabDetails.ak48
                                                : "N/A"
                                        }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Address</label>
                                            <div class="from-label-value text-capitalize">
                                                {{
                                            AadharTabDetails.ak42
                                                ? AadharTabDetails.ak42
                                                : "N/A"
                                        }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Pincode</label>
                                            <div class="from-label-value">
                                                {{
                                            AadharTabDetails.ak97
                                                ? AadharTabDetails.ak97
                                                : "N/A"
                                        }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="aadhardivdisplayflag == 0">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="custom-form-group">
                                                    <label for="formaadharcardinput" class="form-label">Aadhar No.
                                                        <span class="text-danger">*</span></label>
                                                    <input type="text" @input="checkAadharNumber()"
                                                        v-model="aadharcardnumber" class="form-control text-uppercase"
                                                        id="formaadharcardinput" placeholder="Enter Aadhar No."
                                                        autocomplete="off" maxlength="12" @paste="onPasteMobile"
                                                        @keypress="onlyNumber" :disabled="displayMobilePopUp" />
                                                    <span class="custom-error" v-if="aadharerr">
                                                        {{ aadharerr }}
                                                    </span>
                                                    <span class="custom-error" v-if="sendOTPMessage">{{ sendOTPMessage
                                                        }}</span>
                                                </div>
                                            </div>
                                            <div class="
                                            col-lg-2 col-md-2 col-12
                                            " v-if="!displayMobilePopUp">
                                                <div class="custom-form-group" style="margin-top: 26px;">
                                                    <button type="button" class="header-btn-outer btn btn-primary"
                                                        :style="{ width: '100px' }" @click="sendAadharOtp()"
                                                        :disabled="aadharcardnumber.length != 12 || showloader">
                                                        <span v-if="!showloader">Send OTP</span>
                                                        <div class="
                                                    spinner-border
                                                    text-light
                                                    custom-spinner-loader-btn
                                                " role="status" v-if="showloader">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12" v-if="displayMobilePopUp">
                                                <div class="custom-form-group">
                                                    <label for="formaadharcardinput" class="form-label">Enter OTP <span
                                                            class="text-danger">*</span></label>
                                                    <input type="text" v-model="aadharotp"
                                                        class="form-control otp-input-space" id="formaadharcardinput"
                                                        placeholder="Enter OTP" autocomplete="off" maxlength="6"
                                                        minlength="6" @keypress="onlyNumber" @paste="onPasteMobile"
                                                        :disabled="mobile_otp_status == 1" />
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <span class="custom-error" v-if="mobileotperr">
                                                            {{ mobileotperr }}
                                                        </span>
                                                        <span v-if="timerMobileStatus" class="otp-counter-text mt-1">{{
                                        timerMobileInterval
                                    }}</span>
                                                        <button @click="resendAadharOtp()"
                                                            class="btn btn-link aadhar-resent-otp-btn"
                                                            v-if="resentotpbtnstatus" :style="{ width: '80px' }"
                                                            :disabled="showresentloader">
                                                            <span v-if="!showresentloader">Resend OTP</span>
                                                            <div class="spinner-border custom-spinner-icon-loader-btn"
                                                                role="status" v-if="showresentloader">
                                                                <span class="visually-hidden">Loading...</span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="
                                            col-lg-2 col-md-2 col-12
                                            " v-if="displayMobilePopUp">
                                                <div class="custom-form-group" style="margin-top: 26px;">
                                                    <button type="button" class="header-btn-outer btn btn-success"
                                                        @click="verifyAadharOtp()" :style="{ width: '80px' }"
                                                        :disabled="aadharotp.length != 6 || showloader">
                                                        <span v-if="!showloader">Verify</span>
                                                        <div class="
                                                    spinner-border
                                                    text-light
                                                    custom-spinner-loader-btn
                                                " role="status" v-if="showloader">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" v-if="alreadyExitMessage">
                                            <div class="col-12">
                                                <div class="custom-img-error">{{ this.alreadyExitMessage }}</div>
                                            </div>
                                        </div>
                                        <div class="row" v-if="systemlimiterrormsg">
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <span class="custom-img-error">{{ systemlimiterrormsg }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="showloaderaadharmodal">
                            <div class="custom-modal-spinner-loader">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="voterAddStepAadharStatus">
                    <div class="col-12 text-end">
                        <button type="button" :disabled="addvoteraadharbuttonstatus || addvoteraadharloader"
                            class="btn modal-bulk-next-btn" @click="addVoterCardDetailbtn()">
                            <span v-if="!addvoteraadharloader">Next</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="addvoteraadharloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- Create Voter Stepper First end here -->
                <!-- Create Voter Stepper First start here -->
                <div class="modal-body modal-body-scroll-outer pt-3" v-if="voterAddStepVoterStatus">
                    <div style="position: relative;
    min-height: 397px;">
                        <div v-if="!showloadervotermodal">
                            <div v-if="voterdivdisplayflag == 1">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3 col-12">
                                                <div class="custom-form-group">
                                                    <label for="formVoterInput" class="form-label">Voter Id
                                                        <span class="text-danger">*</span></label>
                                                    <input type="text" v-model="votercardnumber"
                                                        class="form-control text-uppercase" id="formLVoterameInput"
                                                        placeholder="Enter Voter Id" disabled autocomplete="off"
                                                        maxlength="10" />
                                                    <span class="custom-error" v-if="voteriderr">
                                                        {{ voteriderr }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <div class="custom-img-error" style="height: 20px;"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="voterarraylength == 1">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Voter Number</label>
                                                    <div class="from-label-value text-uppercase">
                                                        {{
                                        votergovdata.ak35 ? votergovdata.ak35 : "N/A"
                                    }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Name</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                            votergovdata.ak5 ? votergovdata.ak5 : "N/A"
                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Age</label>
                                                    <div class="from-label-value">
                                                        {{
                                            votergovdata.ak19 ? votergovdata.ak19 : "N/A"
                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Gender</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                            votergovdata.ak22
                                                ? votergovdata.ak22 == 1
                                                    ? "Male"
                                                    : votergovdata.ak22 == 2
                                                        ? "Female"
                                                        : "Others"
                                                : "N/A"
                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Part Name</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                            votergovdata.ab4 ? votergovdata.ab4 : "N/A"
                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Part Number</label>
                                                    <div class="from-label-value">
                                                        {{
                                            votergovdata.ak85 ? votergovdata.ak85 : "N/A"
                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Polling Station</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                            votergovdata.ab7 ? votergovdata.ab7 : "N/A"
                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Area</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                            votergovdata.ak44
                                                ? votergovdata.ak44
                                                : "N/A"
                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">State</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                            votergovdata.ak48
                                                ? votergovdata.ak48
                                                : "N/A"
                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Assembly Constituency</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                            votergovdata.ab22
                                                ? votergovdata.ab22
                                                : "N/A"
                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Assembly Constituency Number</label>
                                                    <div class="from-label-value">
                                                        {{
                                            votergovdata.ab23
                                                ? votergovdata.ab23
                                                : "N/A"
                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Parliamentary Constituency</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                            votergovdata.ab24
                                                ? votergovdata.ab24
                                                : "N/A"
                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-start" v-if="voterarraylength > 1">
                                    <ul class="nav nav-tabs custom-vertical-modal-tab flex-column me-3" id="myTab"
                                        role="tablist">
                                        <li class="nav-item" role="presentation"
                                            v-for="(votergovdata, index) in votergovdata" :key="index">
                                            <button class="nav-link" :class="votergovdata.ab26 == 1 ? 'active' : ''"
                                                :id="'headingNumberSet' + index" data-bs-toggle="tab"
                                                :data-bs-target="'#collapseVirtualNumber' + index" type="button"
                                                role="tab" aria-selected="true">
                                                <span class="d-flex justify-content-between align-items-center"><span>{{
                                        votergovdata.ak35
                                    }}</span>
                                                    <span class="user-badge status-active"
                                                        v-if="votergovdata.ab26 == 1"><i
                                                            class="pi pi-check-circle text-success me-1"
                                                            :style="{ fontSize: '10px' }"></i>Primary</span></span>
                                            </button>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade" :class="votergovdata.ab26 == 1 ? 'show active' : ''"
                                            :aria-labelledby="'headingNumberSet' + index" role="tabpanel"
                                            :id="'collapseVirtualNumber' + index"
                                            v-for="(votergovdata, index) in votergovdata" :key="index">
                                            <!-- <div class="row" v-if="votergovdata.ab26 != 1">
                                                <div class="col-lg-12 col-12 pe-4 text-end">
                                                    <label class="from-label-value me-3">Set as Primary Voter Id</label>
                                                    <InputSwitch @change="confirmModalOpen(votergovdata)" />
                                                </div>
                                            </div>
                                            <Divider v-if="votergovdata.ab26 != 1" /> -->
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Voter Number</label>
                                                        <div class="from-label-value text-uppercase">
                                                            {{
                                        votergovdata.ak35 ? votergovdata.ak35 : "N/A"
                                    }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Name</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                            votergovdata.ak5 ? votergovdata.ak5 : "N/A"
                                        }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Age</label>
                                                        <div class="from-label-value">
                                                            {{
                                            votergovdata.ak19 ? votergovdata.ak19 : "N/A"
                                        }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Gender</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                            votergovdata.ak22
                                                ? votergovdata.ak22 == 1
                                                    ? "Male"
                                                    : votergovdata.ak22 == 2
                                                        ? "Female"
                                                        : "Others"
                                                : "N/A"
                                        }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Part Name</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                            votergovdata.ab4 ? votergovdata.ab4 : "N/A"
                                        }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Part Number</label>
                                                        <div class="from-label-value">
                                                            {{
                                            votergovdata.ak85 ? votergovdata.ak85 : "N/A"
                                        }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Polling Station</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                            votergovdata.ab7 ? votergovdata.ab7 : "N/A"
                                        }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Area</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                            votergovdata.ak44
                                                ? votergovdata.ak44
                                                : "N/A"
                                        }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">State</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                            votergovdata.ak48
                                                ? votergovdata.ak48
                                                : "N/A"
                                        }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Assembly Constituency</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                            votergovdata.ab22
                                                ? votergovdata.ab22
                                                : "N/A"
                                        }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Assembly Constituency Number</label>
                                                        <div class="from-label-value">
                                                            {{
                                            votergovdata.ab23
                                                ? votergovdata.ab23
                                                : "N/A"
                                        }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Parliamentary Constituency</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                            votergovdata.ab24
                                                ? votergovdata.ab24
                                                : "N/A"
                                        }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="voterdivdisplayflag == 0">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3 col-12">
                                                <div class="custom-form-group">
                                                    <label for="formVoterInput" class="form-label">Voter Id
                                                        <span class="text-danger">*</span></label>
                                                    <input type="text" v-model="votercardnumber"
                                                        class="form-control text-uppercase" id="formLVoterameInput"
                                                        placeholder="Enter Voter Id" autocomplete="off"
                                                        maxlength="10" />
                                                    <span class="custom-error" v-if="voteriderr">
                                                        {{ voteriderr }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="custom-form-group" style="margin-top: 26px;">
                                                    <button type="button" class="header-btn-outer btn btn-primary"
                                                        @click="fetchvoteriddetails()" :disabled="errStatus || votercardnumber.length == 0 || showloader
                                        " :style="{ width: '104px' }">
                                                        <span v-if="!showloader">Get Details</span>
                                                        <div class="
                                                spinner-border
                                                text-light
                                                custom-spinner-loader-btn
                                            " role="status" v-if="showloader">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col text-end" v-if="voterupdatedisplayflag">
                                                <button type="button" style="margin-top: 26px;"
                                                    class="clear-addvoter-btn btn btn-primary"
                                                    @click="clearallvoterinput()">
                                                    Clear All
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row mb-3" v-if="alreadyExitVoterMessage">
                                            <div class="col-12">
                                                <div class="custom-img-error">{{ this.alreadyExitVoterMessage }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- when enter voter id display confirmation detail to update start here -->
                            <div v-if="voterupdatedisplayflag">
                                <div class="voter-detail-card mb-3">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Voter Number</label>
                                                <div class="from-label-value text-uppercase">
                                                    {{
                                        votergovconfirmdata.ak35 ? votergovconfirmdata.ak35 : "N/A"
                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Name</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{
                                            votergovconfirmdata.ak5 ? votergovconfirmdata.ak5 : "N/A"
                                        }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Age</label>
                                                <div class="from-label-value">
                                                    {{
                                            votergovconfirmdata.ak19 ? votergovconfirmdata.ak19 : "N/A"
                                        }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-12 text-end" v-if="updateVoterBtnStatus">
                                            <button type="button" @click="confirmUpdateModalOpen(votergovconfirmdata)"
                                                class="btn custom-view-detail-btn" :disabled="showupdateloader"
                                                :style="{ width: '70px' }">
                                                <span v-if="!showupdateloader">Confirm</span>
                                                <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                                                    v-if="showupdateloader">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Gender</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{
                                        votergovconfirmdata.ak22
                                            ? votergovconfirmdata.ak22 == 1
                                                ? "Male"
                                                : votergovconfirmdata.ak22 == 2
                                                    ? "Female"
                                                    : "Others"
                                            : "N/A"
                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Part Name</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{
                                            votergovconfirmdata.ab4 ? votergovconfirmdata.ab4 : "N/A"
                                        }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Part Number</label>
                                                <div class="from-label-value">
                                                    {{
                                            votergovconfirmdata.ak85 ? votergovconfirmdata.ak85 : "N/A"
                                        }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Polling Station</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{
                                            votergovconfirmdata.ab7 ? votergovconfirmdata.ab7 : "N/A"
                                        }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Area</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{
                                            votergovconfirmdata.ak44
                                                ? votergovconfirmdata.ak44
                                                : "N/A"
                                        }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">State</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{
                                            votergovconfirmdata.ak48
                                                ? votergovconfirmdata.ak48
                                                : "N/A"
                                        }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Assembly Constituency</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{
                                            votergovconfirmdata.ab22
                                                ? votergovconfirmdata.ab22
                                                : "N/A"
                                        }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Assembly Constituency Number</label>
                                                <div class="from-label-value">
                                                    {{
                                            votergovconfirmdata.ab23
                                                ? votergovconfirmdata.ab23
                                                : "N/A"
                                        }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Parliamentary Constituency</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{
                                            votergovconfirmdata.ab24
                                                ? votergovconfirmdata.ab24
                                                : "N/A"
                                        }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- when enter voter id display confirmation detail to update end here -->
                        </div>
                        <div v-if="showloadervotermodal">
                            <div class="custom-modal-spinner-loader">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="voterAddStepVoterStatus">
                    <div class="col-12 text-end">
                        <button type="button" class="btn modal-bulk-next-btn" @click="addFirstStepDetailbtn()"
                            :disabled="voterdivdisplayflag == 0 || addvotercardloader">
                            <span v-if="!addvotercardloader">Next</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="addvotercardloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- Create Voter Stepper Second end here -->
                <!-- Create Voter Stepper First start here -->
                <div class="modal-body modal-body-scroll-outer pt-3" v-if="voterAddStepFirstStatus">
                    <div style="position: relative;
    min-height: 397px;">
                        <div v-if="!showloadervotermodal">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formEmailInput" class="form-label">Email Id<span
                                                class="text-danger">*</span></label>
                                        <input type="text" v-model="add.email" class="form-control" id="formEmailInput"
                                            placeholder="Enter Email Id" autocomplete="off" maxlength="50" />
                                        <div class="custom-error" v-if="v$.add.email.$error">
                                            {{ v$.add.email.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Blood Group</label>
                                        <Multiselect v-model="add.bloodgroup" :options="bloodgroupList"
                                            :searchable="true" label="label" placeholder="Select"
                                            class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                                            :object="true" noOptionsText="No Result" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label for="formLandmarkInput" class="form-label">Nearest Landmark</label>
                                        <input type="text" v-model="add.landmark" class="form-control text-capitalize"
                                            id="formLandmarkInput" placeholder="Enter Landmark" autocomplete="off"
                                            maxlength="50" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12" v-show="this.allowaddvoterprofessionalinfo != 0">
                                    <div class="custom-form-group">
                                        <label class="form-label">Profession</label>
                                        <Multiselect v-model="add.profession" :options="professionList"
                                            :searchable="true" label="label" placeholder="Select"
                                            class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                                            :object="true" noOptionsText="No Result" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12" v-show="!isCompanyNameExist && this.allowaddvoterprofessionalinfo != 0">
                                    <div class="custom-form-group">
                                        <label class="form-label">Company / Business Name</label>
                                        <Multiselect v-model="add.companyname" :options="companyList" :searchable="true"
                                            label="label" placeholder="Search Company Name" class="multiselect-custom"
                                            :canClear="true" :closeOnSelect="true" :object="true"
                                            noOptionsText="No Result Found" :createOption="false" />
                                        <label style="line-height: 14px;">
                                            Not found?,
                                            <span>
                                                <a class="p-ai-center p-text-right cursor-pointer"
                                                    style="color: #3f51b5" @click="toggleCompanyName">Click
                                                    here to add</a>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12" v-show="isCompanyNameExist && this.allowaddvoterprofessionalinfo != 0">
                                    <div class="custom-form-group">
                                        <label for="createcom" class="form-label">Add Company / Business Name</label>
                                        <input type="text" class="form-control" @input="createOtherCompanyName"
                                            placeholder="Enter Company / Business Name" autocomplete="off"
                                            maxlength="30" />
                                        <label style="line-height: 14px;">
                                            For search,
                                            <span>
                                                <a class="p-ai-center p-text-right cursor-pointer"
                                                    style="color: #3f51b5" @click="toggleCompanyName">Click
                                                    here</a>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12" v-show="!isDesignationExist && this.allowaddvoterprofessionalinfo != 0">
                                    <div class="custom-form-group">
                                        <label class="form-label">Designation</label>
                                        <Multiselect v-model="add.designation" :options="clientdesignationList"
                                            :searchable="true" label="label" placeholder="Search Add Designation"
                                            class="multiselect-custom text-capitalize" :canClear="true"
                                            :closeOnSelect="true" noOptionsText="No Result Found" :object="true"
                                            :createOption="false" />
                                        <label style="line-height: 14px;">
                                            Not found?,
                                            <span>
                                                <a class="p-ai-center p-text-right cursor-pointer"
                                                    style="color: #3f51b5" @click="toggleDesginationName">Click
                                                    here to add</a>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12" v-show="isDesignationExist && this.allowaddvoterprofessionalinfo != 0">
                                    <div class="custom-form-group">
                                        <label for="createdesingation" class="form-label">Add Designation</label>
                                        <input type="text" class="form-control" @input="createOtherDesignationName"
                                            placeholder="Enter Designation Name" autocomplete="off" maxlength="30" />
                                        <label style="line-height: 14px;">
                                            For search,
                                            <span>
                                                <a class="p-ai-center p-text-right cursor-pointer"
                                                    style="color: #3f51b5" @click="toggleDesginationName">Click
                                                    here</a>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-4">
                                    <div class="custom-form-group">
                                        <label class="form-label">Upload Photo</label>
                                        <div class="custom-group-radio-box-btn">
                                            <input type="radio" class="btn-check" v-model="add.uploadphototype"
                                                name="upload-type-option" id="selectaadhar" autocomplete="off"
                                                value="1" />
                                            <label class="btn custom-radio-box-btn text-capitalize"
                                                for="selectaadhar">Aadhar Photo</label>
                                            <input type="radio" class="btn-check" v-model="add.uploadphototype"
                                                name="upload-type-option" id="selectmanual" autocomplete="off"
                                                value="2" />
                                            <label class="btn custom-radio-box-btn text-capitalize"
                                                for="selectmanual">Manual Photo</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-4" v-if="add.uploadphototype == 1">
                                    <img v-if="this.aadharprofileimage != ''"
                                        :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + this.aadharprofileimage"
                                        class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                      voter-photo-img
                                    " alt="" />
                                </div>
                                <div class="col-lg-12 col-md-12 col-12" v-if="add.uploadphototype == 2">
                                    <div class="row">
                                        <div class="col-lg-3 col-md-3 col-12">
                                            <div class="custom-form-group">
                                                <label class="form-label"></label>
                                                <FileUpload mode="basic" accept="image/*" :auto="true"
                                                    ref="votercreateimg" class="custom-upload-btn-ultima"
                                                    v-model="add.votercreateimg"
                                                    v-on:change="handleUploadVoterCreate()" />
                                                <div class="custom-error" v-if="errorprocreateimage">{{
                                        errorprocreateimage
                                    }}</div>
                                    <div class="custom-error" v-if="v$.add.votercreateimg.$error">
                                            {{ v$.add.votercreateimg.$errors[0].$message }}
                                        </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-12"
                                            v-if="createpreviewurl == null && (existsmanualphoto != '' && existsmanualphoto != null)">
                                            <img :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/voterphoto/' + this.existsmanualphoto"
                                                class="
                                            rounded
                                            bg-light
                                            me-auto
                                            d-block
                                            voter-photo-img
                                            " alt="" />
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-12" v-if="createpreviewurl != null">
                                            <img :src="createpreviewurl.objectURL" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img
                                  " alt="" />
                                        </div>
                                        <div class="text-primary" v-if="createpreviewurl != null">{{
                                        createpreviewurl.name
                                    }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="showloadervotermodal">
                            <div class="custom-modal-spinner-loader">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="voterAddStepFirstStatus">
                    <div class="col-12 text-end">
                        <button type="button" class="btn modal-bulk-next-btn" @click="CreateVoterDetailbtn()"
                            :disabled="addvoterregloader">
                            <span v-if="!addvoterregloader">Next</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="addvoterregloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- Create Voter Stepper First end here -->
                <!-- Create Voter Stepper Second start here -->
                <div class="modal-body modal-body-scroll-outer pt-3" v-if="voterAddStepSecondStatus">
                    <div style="position: relative;
    min-height: 397px;">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="custom-form-group">
                                            <label class="form-label">Party Related Designation<span
                                                    class="text-danger">*</span></label>
                                            <Multiselect v-model="adduser.userdesignation"
                                                :options="userdesignationList" :searchable="true" label="label"
                                                placeholder="Select" class="multiselect-custom" :canClear="true"
                                                :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                                            <div class="custom-error" v-if="v$.adduser.userdesignation.$error">
                                                {{ v$.adduser.userdesignation.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-12">
                                        <div class="custom-form-group" v-if="teamleadList != null">
                                            <label class="form-label">Party Related Team Lead<span
                                                    v-if="teamleadList != null" class="text-danger">*</span></label>
                                            <Multiselect v-model="adduser.teamleadname" :options="teamleadList"
                                                :searchable="true" label="label" placeholder="Select"
                                                class="multiselect-custom text-capitalize" :canClear="true"
                                                :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                                            <div class="custom-error" v-if="v$.adduser.teamleadname.$error">
                                                {{ v$.adduser.teamleadname.$errors[0].$message }}
                                            </div>
                                        </div>
                                        <div class="custom-form-group" v-if="teamleadList == null" style="margin-top: 26px;">
                                            <div class="form-check form-check-inline custom-inline-checkbox">
                                                <input class="form-check-input" disabled v-model="adduser.superuser"
                                                    type="checkbox" id="whatsappimport" value="true" checked>
                                                <label class="form-check-label" for="whatsappimport">Set As a Supreme
                                                    User</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-5 col-md-5 col-12">
                                        <div class="custom-form-group">
                                            <label class="form-label">Permission Type<span
                                                    class="text-danger">*</span></label>
                                            <div class="custom-group-radio-box-btn">
                                                <input type="radio" class="btn-check" v-model="adduser.roletype"
                                                    name="role-option" id="rolewise" autocomplete="off" value="1" />
                                                <label class="btn custom-radio-box-btn text-capitalize"
                                                    for="rolewise">Set
                                                    Wise</label>
                                                <input type="radio" class="btn-check" v-model="adduser.roletype"
                                                    name="role-option" id="manualrole" autocomplete="off" value="2" />
                                                <label class="btn text-capitalize" for="manualrole">Manual
                                                    Permission</label>
                                                <div class="custom-error" v-if="v$.adduser.roletype.$error">
                                                    {{ v$.adduser.roletype.$errors[0].$message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12" v-if="adduser.roletype == 1">
                                        <div class="custom-form-group">
                                            <label class="form-label">Permission Set<span
                                                    class="text-danger">*</span></label>

                                            <Multiselect v-model="adduser.userrole" :options="userrole_list"
                                                :searchable="false" label="label" placeholder="Select Permission Set"
                                                class="multiselect-custom text-capitalize" :canClear="true"
                                                :closeOnSelect="true" />
                                            <div class="custom-error"
                                                v-if="v$.adduser.userrole.$error && adduser.roletype == 1">
                                                {{ v$.adduser.userrole.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="adduser.roletype == 2">
                                    <div class="col-12">
                                        <div class="custom-form-group">
                                            <label for="formLastNameInput" class="form-label">Select Modules<span
                                                    class="text-danger">*</span></label>
                                            <div class="flex flex-wrap gap-2 mb-2">
                                                <button type="button" class="btn custom-view-detail-btn me-2"
                                                    @click="expandAll"><i class="pi pi-plus me-2"
                                                        style="font-size: 0.7rem"></i>Expand All</button>
                                                <button type="button" class="btn custom-view-detail-btn"
                                                    @click="collapseAll"><i class="pi pi-minus me-2"
                                                        style="font-size: 0.7rem"></i>Collapse All</button>
                                            </div>
                                            <Tree :value="nodes" selectionMode="checkbox"
                                                v-model:selectionKeys="adduser.selectedTreeValue"
                                                :expandedKeys="expandedKeys">
                                            </Tree>
                                            <div class="custom-error"
                                                v-if="v$.adduser.selectedTreeValue.$error && adduser.roletype == 2">
                                                {{ v$.adduser.selectedTreeValue.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="voterAddStepSecondStatus">
                    <div class="col-12 text-end">
                        <button type="button" class="btn modal-bulk-next-btn" @click="CreateVoterPermissionbtn()"
                            :disabled="adduserregloader">
                            <span v-if="!adduserregloader">
                                <span>Next</span>
                            </span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="adduserregloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- Create Voter Stepper Second end here -->
                <!-- Create Voter Stepper Third Stepper Start Here -->
                <div class="modal-body modal-body-scroll-outer pt-3" v-if="voterAddStepThirdStatus">
                    <div style="position: relative;
    min-height: 443px;">
                        <div v-if="!showloadervotermodal">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to use Mobile App : </label>
                                        <InputSwitch v-model="addallow.usermobapppermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to make Outgoing Calls to voters :
                                        </label>
                                        <InputSwitch v-model="addallow.displaymakeoutgoingcallvoterpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to display voter's Mobile Number :
                                        </label>
                                        <InputSwitch v-model="addallow.usermobpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to display voter's Email ID :
                                        </label>
                                        <InputSwitch v-model="addallow.useremailpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to display Aadhaar No. : </label>
                                        <InputSwitch v-model="addallow.useraadharpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to display user's Mobile Number :
                                        </label>
                                        <InputSwitch v-model="addallow.displayusermobilenumberpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to display user's Email ID :
                                        </label>
                                        <InputSwitch v-model="addallow.displayuseremailidpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to display Karyakarta's Mobile Number
                                            : </label>
                                        <InputSwitch v-model="addallow.displaykaryakartamobilenumberpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to display Karyakarta's Email ID :
                                        </label>
                                        <InputSwitch v-model="addallow.displaykaryakartaemailidpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to add a Voter & Voter's Family :
                                        </label>
                                        <InputSwitch v-model="addallow.displayaddvoterfamilypermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to edit Voter Details manually :
                                        </label>
                                        <InputSwitch v-model="addallow.usereditpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to add Voter's Professional Information :
                                        </label>
                                        <InputSwitch v-model="addallow.displayaddvoterprofessionalinfopermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to modify Voter's Professional Information :
                                        </label>
                                        <InputSwitch v-model="addallow.displaymodifyvoterprofessionalinfopermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to manage or modify Family Relations of Voters :
                                        </label>
                                        <InputSwitch v-model="addallow.displaymanagemodifyfamilyrelationpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to remove a Member from Voter's Family Member Group :
                                        </label>
                                        <InputSwitch v-model="addallow.displayremovememberfromfamilygrouppermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="customer-label-group divider-custom-list customer-label-group">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <label class="from-label-value me-3">Allow to Add Tags to Voter : </label>
                                            <InputSwitch v-model="addallow.displayvotertagaddpermission" />
                                        </div>
                                        <div class="d-flex align-items-center justify-content-end" v-if="this.addallow.displayvotertagaddpermission == true">
                                            <div class="custom-form-group mb-0 d-flex">
                                                <div class="form-check custom-chat-radio-form form-check-inline">
                                                    <input type="radio" class="form-check-input" v-model="addallow.displayaddtagresponsepermission" name="none-option" id="directresponse"
                                                        autocomplete="off" value="0" />
                                                    <label class="form-check-label text-capitalize" for="directresponse">Directly</label>
                                                </div>
                                                <div class="form-check custom-chat-radio-form form-check-inline">
                                                    <input type="radio" class="form-check-input" v-model="addallow.displayaddtagresponsepermission" name="with-option" id="withapproval"
                                                        autocomplete="off" value="1" />
                                                    <label class="form-check-label text-capitalize" for="withapproval">With Approval</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="customer-label-group divider-custom-list customer-label-group">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <label class="from-label-value me-3">Allow to Remove Tags from Voter : </label>
                                            <InputSwitch v-model="addallow.displayvotertagremovalpermission" />
                                        </div>
                                        <div class="d-flex align-items-center justify-content-end" v-if="this.addallow.displayvotertagremovalpermission == true">
                                            <div class="custom-form-group mb-0 d-flex">
                                                <div class="form-check custom-chat-radio-form form-check-inline">
                                                    <input type="radio" class="form-check-input" v-model="addallow.displayremovaltagresponsepermission" name="none-removeoption" id="removedirectresponse"
                                                        autocomplete="off" value="0" />
                                                    <label class="form-check-label text-capitalize" for="removedirectresponse">Directly</label>
                                                </div>
                                                <div class="form-check custom-chat-radio-form form-check-inline">
                                                    <input type="radio" class="form-check-input" v-model="addallow.displayremovaltagresponsepermission" name="with-removeoption" id="removewithapproval"
                                                        autocomplete="off" value="1" />
                                                    <label class="form-check-label text-capitalize" for="removewithapproval">With Approval</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to Mark Voter / User / Karyakarta as a VIP / VVIP :
                                        </label>
                                        <InputSwitch v-model="addallow.displaymarkvoteruservippermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="customer-label-group">
                                        <div class="customer-label-group">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <label class="from-label-value me-3">Allow to Delete Voter : </label>
                                                <InputSwitch @change="CheckAllowDeleteVoterAccess(addallow.displaydeletevoterpermission)" v-model="addallow.displaydeletevoterpermission" />
                                            </div>
                                            <small class="text-danger" v-if="this.deletevotermessage">{{this.deletevotermessage}}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="showloadervotermodal">
                            <div class="custom-modal-spinner-loader">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="voterAddStepThirdStatus">
                    <div class="col-12 text-end">
                        <button type="button" class="btn modal-bulk-next-btn" @click="addClientUserPermission()"
                            :disabled="addpermissionloader">
                            <span v-if="!addpermissionloader">Submit</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="addpermissionloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- Create Voter Stepper Third Stepper End Here -->
            </div>
        </div>
    </div>
    <!-- Create Mobile No Via Voter Detail end here -->
    <!-- confirmation Update Voter Details modal start here -->
    <div class="modal-mask" v-if="confirm_update_popup_status">
        <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
            <div class="modal-content text-center">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="confirm-dialog-header">Confirmation</div>
                        </div>
                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                                </div>
                                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                                    Are you sure you want to proceed?
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 text-end">
                        <button type="button" :disabled="showconfirmloaderbtn" class="btn confirm-modal-no-btn"
                            @click="confirmUpdateModalClose()">
                            No
                        </button>
                        <button type="button" class="btn confirm-modal-yes-btn" @click="updateVoterPermission()"
                            :disabled="showconfirmloaderbtn">
                            <span v-if="!showconfirmloaderbtn">Yes</span>

                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="showconfirmloaderbtn">
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- confirmation Update Voter Details modal end here -->
    <!-- confirmation Update Aadhar Details modal start here -->
    <div class="modal-mask" v-if="confirm_aadhar_update_popup_status">
        <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
            <div class="modal-content text-center">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="confirm-dialog-header">Confirmation</div>
                        </div>
                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                                </div>
                                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                                    Are you sure you want to proceed?
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 text-end">
                        <button type="button" :disabled="showaconfirmloaderbtn" class="btn confirm-modal-no-btn"
                            @click="confirmAadharUpdateModalClose()">
                            No
                        </button>
                        <button type="button" class="btn confirm-modal-yes-btn" @click="updateAadharReloadPermission()"
                            :disabled="showaconfirmloaderbtn">
                            <span v-if="!showaconfirmloaderbtn">Yes</span>

                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="showaconfirmloaderbtn">
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- confirmation Update Aadhar Details modal end here -->
</template>
<script>
import { required, helpers, requiredIf } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../../service/ApiService";
import moment from 'moment';
export default {
    data() {
        return {
            v$: useValidate(),
            countrycode: { value: 101, label: "+91" },
            countryCodeList: [],
            searchmobile: "",
            searchuniqueid: '',
            btnloader: false,
            editvoterdetailstatus: false,
            addvoterloader: false,
            errorprofileimage: '',
            showloadervotermodal: false,
            genderList: [
                { label: "Male", value: 1 },
                { label: "Female", value: 2 },
                { label: "Other", value: 3 },
            ],
            clientdesignationList: [],
            maxDateBirth: moment().subtract(1, "years")._d,
            minDateBirth: moment().subtract(120, "years")._d,
            userrole_list: [],
            expandedKeys: {},
            nodes: [],
            usersearchalertmodals: false,
            createuserloader: false,
            searchMobileResult: [],
            createvoterdetailstatus: false,
            showloadervoterregmodal: false,
            addvoterregloader: false,
            professionList: [
                { label: "Student", value: 1 },
                { label: "Self Employed", value: 2 },
                { label: "Salaried", value: 3 },
                { label: "Business", value: 4 },
                { label: "Farmer", value: 5 },
                { label: "Retired", value: 6 },
                { label: "Housewife", value: 7 },
            ],
            bloodgroupList: [],
            add: {
                email: "",
                designation: "",
                companyname: "",
                votertype: '',
                landmark: '',
                bloodgroup: '',
                uploadphototype: '',
                votercreateimg: null,
            },
            adduser: {
                votertype: 3,
                roletype: '',
                userrole: "",
                userdesignation: "",
                teamleadname: '',
                superuser: true,
                selectedTreeValue: [],
            },
            userdesignationList: [],
            teamleadList: [],
            errorprocreateimage: '',
            voterAddStepAadharStatus: false,
            voterAddStepVoterStatus: false,
            voterAddStepFirstStatus: false,
            voterAddStepSecondStatus: false,
            voterAddStepThirdStatus: false,
            adduserregloader: false,
            client_info: '',
            voterstorageimgpath: '',
            addallow: {
                usermobpermission: '',
                useremailpermission: '',
                useraadharpermission: '',
                usermobapppermission: true,
                usereditpermission: '',
                displayusermobilenumberpermission: '',
                displayuseremailidpermission: '',
                displaykaryakartamobilenumberpermission: '',
                displaykaryakartaemailidpermission: '',
                displaydeletevoterpermission: '',

                displayaddvoterfamilypermission: '',
                displayaddvoterprofessionalinfopermission: '',
                displaymodifyvoterprofessionalinfopermission: '',
                displaymanagemodifyfamilyrelationpermission: '',
                displayremovememberfromfamilygrouppermission: '',
                displaymarkvoteruservippermission: '',
                displaymakeoutgoingcallvoterpermission: '',

                displayvotertagaddpermission: '',
                displayaddtagresponsepermission: 0,
                displayvotertagremovalpermission: '',
                displayremovaltagresponsepermission: 0,
            },
            createpreviewurl: null,
            exitpreviewurl: null,
            user_storage_fk: '',
            user_info: '',
            alloweditvoterfk: '',
            addpermissionloader: false,
            usercreateerrormsg: '',
            voterexitid: '',
            addharexitid: '',
            voterexitstatus: '',
            exitvotererrormsg: false,
            taggroupList: [],
            showloaderaadharmodal: false,
            aadhardivdisplayflag: 0,
            aadharcardnumber: "",
            aadharerr: "",
            aadharotp: "",
            displayMobilePopUp: false,
            sendOTPMessage: '',
            alreadyExitMessage: '',
            systemlimiterrormsg: '',
            votersid: '',
            showloader: false,
            resentotpbtnstatus: false,
            timerMobileInterval: null,
            timerMobileCount: 120,
            timerMobileStatus: false,
            mobileotperr: "",
            mobile_otp_status: false,
            addvoteraadharloader: false,
            voterdivdisplayflag: 0,
            voterupdatedisplayflag: false,
            votercardnumber: '',
            voteriderr: '',
            errStatus: false,
            alreadyExitVoterMessage: '',
            addvotercardloader: false,
            addvotercardbuttonstatus: true,
            updateVoterBtnStatus: false,
            updatevoterid: '',
            confirm_update_popup_status: false,
            showconfirmloaderbtn: false,
            showaconfirmloaderbtn: false,
            confirm_aadhar_update_popup_status: false,
            addmorebtncount: '',
            allowshowaadharvoterfk: '',
            mobile_number: '',
            aadharupdatebtnflag: false,
            AadharTabDetails: '',
            votergovdata: '',
            voterarraylength: 0,
            first_name: '',
            disabledinput: false,
            addvoteraadharbuttonstatus: true,
            aadharUpdateBtnDaysMsg: '',
            companyList: [],
            showupdateloader: false,
            aadharprofileimage: '',
            existsmanualphoto: '',
            mobilealertmsg: '',
            isCompanyNameExist: false,
            isDesignationExist: false,
            votergovconfirmdata: '',
            allowshowusermobile: '',
            allowshowmobilekaryakartasfk: '',
            allowshowmobilevoterfk: '',
            allowshowemailuserfk: '',
            allowshowemailkaryakartasfk: '',
            allowshowemailvoterfk: '',
            deletevotermessage: '',
            allowaddvoterprofessionalinfo: 0,
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    validations() {
        return {
            add: {
                email: { required: helpers.withMessage('Please enter email', required) },
                votercreateimg: {
                    requiredIf: helpers.withMessage(
                        "Please upload photo",
                        requiredIf(
                            this.add.uploadphototype == 2
                        )
                    ),
                },
            },
            adduser: {
                roletype: {
                    requiredIf: helpers.withMessage(
                        "Please select role type",
                        requiredIf(
                            this.adduser.votertype == 3
                        )
                    ),
                },
                userrole: {
                    requiredIf: helpers.withMessage(
                        "Please select role",
                        requiredIf(
                            this.adduser.roletype == 1
                        )
                    ),
                },
                selectedTreeValue: {
                    requiredIf: helpers.withMessage(
                        "Please select module",
                        requiredIf(
                            this.adduser.roletype == 2
                        )
                    ),
                },
                userdesignation: {
                    requiredIf: helpers.withMessage(
                        "Please select designation",
                        requiredIf(
                            this.adduser.votertype == 2 || this.adduser.votertype == 3
                        )
                    ),
                },
                teamleadname: {
                    requiredIf: helpers.withMessage(
                        "Please select team lead name",
                        requiredIf(
                            (this.adduser.votertype == 2 || this.adduser.votertype == 3) && this.teamleadList != null)
                    ),
                },
            },
        };
    },
    mounted() {
        this.getclientdesignations();
        this.getClientRoles();
        this.getuserdesignation();
        this.getactiveusers();
        this.getcompanylist();
        this.getbloodgroups();
        this.getModules();
        this.gettags();
        this.client_info = JSON.parse(localStorage.client_info);
        this.voterstorageimgpath = this.client_info.maa24;
        this.user_info = JSON.parse(localStorage.user);
        this.user_storage_fk = this.user_info.user_id;
        this.alloweditvoterfk = this.user_info.ak109;
        this.allowshowaadharvoterfk = this.user_info.ak107;
        this.allowshowusermobile = this.user_info.ak123;
        this.allowshowmobilekaryakartasfk = this.user_info.ak125;
        this.allowshowmobilevoterfk = this.user_info.ak105;
        this.allowshowemailuserfk = this.user_info.ak124;
        this.allowshowemailkaryakartasfk = this.user_info.ak126;
        this.allowshowemailvoterfk = this.user_info.ak106;
        this.allowaddvoterprofessionalinfo = this.user_info.ak199;
        // if (this.user_info.ak165 == 1) {
        //     this.addallow.displaydeletevoterpermission = true;
        //     } else {
        //     this.addallow.displaydeletevoterpermission = false;
        //     }
    },
    methods: {
        createOtherCompanyName(e) {
            this.add.companyname = { value: 0, label: e.target.value };
        },
        toggleCompanyName() {
            this.isCompanyNameExist = !this.isCompanyNameExist;
            this.add.companyname = '';
        },
        createOtherDesignationName(e) {
            this.add.designation = { value: 0, label: e.target.value };
        },
        toggleDesginationName() {
            this.isDesignationExist = !this.isDesignationExist;
            this.add.designation = '';
        },
        getcompanylist(e) {
            this.ApiService.getcompanylist(e).then((data) => {
                if (data.status == 200) {
                    this.companyList = data.data;
                } else {
                    this.companyList = null;
                }
            });
        },
        onchangesearchmobile() {
            this.searchuniqueid = '';
        },
        onchangesearchsystemno() {
            this.searchmobile = '';
        },
        maskedNumber(getvalue) {
            const maskedDigits = 'X'.repeat(getvalue.length - 4);
            const FirstThreeDigits = getvalue.slice(0, 2);
            const lastThreeDigits = getvalue.slice(-2);
            return FirstThreeDigits + maskedDigits + lastThreeDigits;
        },
        getModules(ev) {
            this.ApiService.getModules(ev).then(data => {
                if (data.status == 200) {
                    this.nodes = data.data;
                }
            })
        },
        getbloodgroups(e) {
            this.ApiService.getbloodgroups(e).then((data) => {
                if (data.status == 200) {
                    this.bloodgroupList = data.data;
                } else {
                    this.bloodgroupList = null;
                }
            });
        },
        gettags(e) {
            this.ApiService.gettags(e).then((data) => {
                if (data.status == 200) {
                    this.taggroupList = data.data;
                } else {
                    this.taggroupList = null;
                }
            });
        },
        CreateVoterDetailbtn() {
            this.submitted = true;
            this.errormsg = '';
            this.v$.add.$validate();
            var formData = new FormData();
            formData.append("ak1", this.votersid);
            if (this.add.email) {
                formData.append("ak23", this.add.email);
            }
            if (this.add.landmark) {
                formData.append('ak59', this.add.landmark);
            }
            if (this.add.bloodgroup) {
                formData.append("ak20", this.add.bloodgroup.value);
                formData.append("ak21", this.add.bloodgroup.label);
            }
            if (this.add.profession) {
                formData.append("ak66", this.add.profession.value);
                formData.append("ak67", this.add.profession.label);
            }
            if (this.add.companyname) {
                formData.append("ak88", this.add.companyname.label);
                formData.append("ak145", this.add.companyname.value);
            }
            if (this.add.designation) {
                formData.append("ak83", this.add.designation.value);
                formData.append("ak84", this.add.designation.label);
            }
            if(this.add.uploadphototype == 2) {
                if (this.add.votercreateimg) {
                    formData.append("voterphoto", this.add.votercreateimg);
                }
                formData.append('ak151', 2);
            } else {
                formData.append('ak151', 1);
            }
            if (!this.v$.add.$error && !this.errorprocreateimage) {
                this.addvoterregloader = true;
                this.ApiService.updatevoteruserdetails(formData).then((data) => {
                    if (data.status == 200) {
                        this.addvoterregloader = false;
                        var successMsg = data.message;
                        this.$toast.open({
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.voterAddStepFirstStatus = false;
                        this.voterAddStepSecondStatus = true;
                        this.showloadervotermodal = false;
                        this.adduserregloader = false;
                        this.showloader = false;
                        this.add.email = '';
                        this.add.companyname = '';
                        this.add.designation = '';
                        this.add.landmark = '';
                        this.add.bloodgroup = '';
                        this.add.profession = '';
                    } else {
                        var errorMsg = data.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.addvoterregloader = false;
                        this.voterAddStepFirstStatus = true;
                    }
                });
            } else {
                this.$error;
            }
        },
        CreateVoterPermissionbtn() {
            this.v$.adduser.$validate();
            let fields = {};
            fields["ak1"] = this.votersid;
            fields["ak29"] = this.adduser.votertype;
            fields["ak24"] = this.mobile_number;
            fields["ak7"] = this.first_name;
            if (this.adduser.roletype) {
                fields["role_type"] = this.adduser.roletype;
            }
            if (this.adduser.userdesignation.value) {
                fields["ak110"] = this.adduser.userdesignation.value;
            }
            if (this.adduser.userdesignation.label) {
                fields["ak111"] = this.adduser.userdesignation.label;
            }
            if (this.adduser.teamleadname.value) {
                fields["ak101"] = this.adduser.teamleadname.value;
            }
            if (this.adduser.teamleadname.label) {
                fields["ak103"] = this.adduser.teamleadname.label;
            } else {
                if(this.adduser.superuser == true) {
                    fields["ak143"] = 1;
                }
            }
            if (this.adduser.roletype == 1) {
                fields["ak39"] = this.adduser.userrole;
            }
            if (this.adduser.roletype == 2) {
                fields["modules"] = this.adduser.selectedTreeValue;
            }

            if (!this.v$.adduser.$error) {
                this.adduserregloader = true;
                this.ApiService.updateclientuserpermissions(fields).then((items) => {
                    if (items.status == 200) {
                        this.adduserregloader = false;
                        this.$toast.open({
                            message: items.message,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.voterAddStepSecondStatus = false;
                        this.voterAddStepThirdStatus = true;
                        this.expandedKeys = {};
                    } else {
                        this.$toast.open({
                            message: items.message,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.adduserregloader = false;
                        this.voterAddStepSecondStatus = false;
                        this.usercreateerrormsg = items.msg;
                    }
                });
            }
        },
        addClientUserPermission() {
            let fields = {};
            fields["ak1"] = this.votersid;
            if (this.addallow.usermobpermission == true) {
                fields["ak105"] = 1;
            } else if (this.addallow.usermobpermission == false) {
                fields["ak105"] = 0;
            }

            if (this.addallow.useremailpermission == true) {
                fields["ak106"] = 1;
            } else if (this.addallow.useremailpermission == false) {
                fields["ak106"] = 0;
            }

            if (this.addallow.useraadharpermission == true) {
                fields["ak107"] = 1;
            } else if (this.addallow.useraadharpermission == false) {
                fields["ak107"] = 0;
            }

            if (this.addallow.usermobapppermission == true) {
                fields["ak108"] = 1;
            } else if (this.addallow.usermobapppermission == false) {
                fields["ak108"] = 0;
            }
            if (this.addallow.usereditpermission == true) {
                fields["ak109"] = 1;
            } else if (this.addallow.usereditpermission == false) {
                fields["ak109"] = 0;
            }
            if (this.addallow.displayusermobilenumberpermission == true) {
                fields["ak123"] = 1;
            } else if (this.addallow.displayusermobilenumberpermission == false) {
                fields["ak123"] = 0;
            }

            if (this.addallow.displayuseremailidpermission == true) {
                fields["ak124"] = 1;
            } else if (this.addallow.displayuseremailidpermission == false) {
                fields["ak124"] = 0;
            }

            if (this.addallow.displaykaryakartamobilenumberpermission == true) {
                fields["ak125"] = 1;
            } else if (this.addallow.displaykaryakartamobilenumberpermission == false) {
                fields["ak125"] = 0;
            }

            if (this.addallow.displaykaryakartaemailidpermission == true) {
                fields["ak126"] = 1;
            } else if (this.addallow.displaykaryakartaemailidpermission == false) {
                fields["ak126"] = 0;
            }
            if (this.addallow.displaydeletevoterpermission == true) {
        fields["ak165"] = 1;
      } else if (this.addallow.displaydeletevoterpermission == false) {
        fields["ak165"] = 0;
      }

            if (this.addallow.displayaddvoterfamilypermission == true) {
                fields["ak196"] = 1;
            } else if (this.addallow.displayaddvoterfamilypermission == false) {
                fields["ak196"] = 0;
            }
            if (this.addallow.displayaddvoterprofessionalinfopermission == true) {
                fields["ak199"] = 1;
            } else if (this.addallow.displayaddvoterprofessionalinfopermission == false) {
                fields["ak199"] = 0;
            }
            if (this.addallow.displaymodifyvoterprofessionalinfopermission == true) {
                fields["ak200"] = 1;
            } else if (this.addallow.displaymodifyvoterprofessionalinfopermission == false) {
                fields["ak200"] = 0;
            }
            if (this.addallow.displaymanagemodifyfamilyrelationpermission == true) {
                fields["ak201"] = 1;
            } else if (this.addallow.displaymanagemodifyfamilyrelationpermission == false) {
                fields["ak201"] = 0;
            }
            if (this.addallow.displayremovememberfromfamilygrouppermission == true) {
                fields["ak202"] = 1;
            } else if (this.addallow.displayremovememberfromfamilygrouppermission == false) {
                fields["ak202"] = 0;
            }
            if (this.addallow.displaymarkvoteruservippermission == true) {
                fields["ak208"] = 1;
            } else if (this.addallow.displaymarkvoteruservippermission == false) {
                fields["ak208"] = 0;
            }
            if (this.addallow.displayvotertagaddpermission == true) {
                fields["ak197"] = 1;
                if(this.addallow.displayaddtagresponsepermission) {
                    fields["ak204"] = this.addallow.displayaddtagresponsepermission;
                }
            } else if (this.addallow.displayvotertagaddpermission == false) {
                fields["ak197"] = 0;
                fields["ak204"] = 0;
            }
            if (this.addallow.displayvotertagremovalpermission == true) {
                fields["ak198"] = 1;
                if(this.addallow.displayremovaltagresponsepermission) {
                    fields["ak205"] = this.addallow.displayremovaltagresponsepermission;
                }
            } else if (this.addallow.displayvotertagremovalpermission == false) {
                fields["ak198"] = 0;
                fields["ak205"] = 0;
            }
            if (this.addallow.displaymakeoutgoingcallvoterpermission == true) {
                fields["ak203"] = 1;
            } else if (this.addallow.displaymakeoutgoingcallvoterpermission == false) {
                fields["ak203"] = 0;
            }
            this.addpermissionloader = true;
            this.ApiService.updateuserallowpermissions(fields).then((items) => {
                if (items.status == 200) {
                    this.addpermissionloader = false;
                    this.$toast.open({
                        message: items.message,
                        type: "success",
                        duration: 3000,
                        position: "top-right",
                    });
                    this.add.primobile = '';
                        this.add.firstname = '';
                        this.searchmobile = '';
                        this.searchuniqueid = '';
                    setTimeout(() => {
                        this.v$.$reset();
                    }, 0);
                    this.voterEditStepThrirdStatus = false;
                    this.createvoterdetailstatus = false;
                    this.redirecttouserlisting();
                } else {
                    this.addpermissionloader = false;
                    this.$toast.open({
                        message: items.message,
                        type: "error",
                        duration: 3000,
                        position: "top-right",
                    });
                }
            });
        },
        getclientdesignations(e) {
            this.ApiService.getclientdesignations(e).then((data) => {
                if (data.status == 200) {
                    this.clientdesignationList = data.data;
                }
            });
        },
        getuserdesignation(e) {
            this.ApiService.getuserdesignations(e).then((data) => {
                if (data.status == 200) {
                    this.userdesignationList = data.data;
                }
            });
        },
        getactiveusers(e) {
            this.ApiService.getactiveusers(e).then((data) => {
                if (data.status == 200) {
                    this.teamleadList = data.data;
                    if (this.user_storage_fk != null) {
                        let datafk = this.user_storage_fk;
                        let vals = this.teamleadList.filter(function (item) {
                            return item.value == datafk;
                        });
                        this.add.voterowner = { value: vals[0].value, label: vals[0].label };
                    }
                } else {
                    this.teamleadList = null;
                }
            });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
        onPasteMobile(event) {
            let mobvalue = event.clipboardData.getData('text');
            if (/^[0-9]+$/.test(mobvalue)) return true;
            else event.preventDefault();
        },
        onlyNumberMobile($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var mobileInput = document.getElementById("formMobileNoInput");
            if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        searchvoterbtn(mobilenumber, searchuniqueid) {
            this.btnloader = true;
            this.ak24 = mobilenumber;
            this.ak120 = searchuniqueid;
            this.ApiService.getUserUsingMobileNumber({ ak24: this.ak24, ak120: this.ak120 }).then((data) => {
                if (data.status == 200) {
                    this.searchMobileResult = data.data;
                    this.addmorebtncount = data.count;
                    this.btnloader = false;
                    this.disabledinput = true;
                    this.mobilealertmsg = '';
                } else {
                    if (data.status == 422) {
                        this.mobilealertmsg = data.message;
                        this.disabledinput = false;
                        this.btnloader = false;
                    }
                    else {
                        this.mobilealertmsg = '';
                        this.searchMobileResult = null;
                        //this.usersearchalertmodals = true;
                        this.btnloader = false;
                        this.createvoterdetailstatus = true;
                        this.voterAddStepAadharStatus = true;
                        this.showloadervotermodal = true;
                        this.mobile_number = mobilenumber;
                        this.disabledinput = false;
                    }
                }
            });
        },
        registervotermodalopen(mob) {
            this.usersearchalertmodals = false;
            this.createvoterdetailstatus = true;
            this.voterAddStepAadharStatus = true;
            this.showloadervotermodal = true;
            this.getclientdesignations();
            this.getuserdesignation();
            this.isCompanyNameExist = false;
            this.isDesignationExist = false;
            this.add.title = '';
            this.add.middlename = '';
            this.add.lastname = '';
            this.add.email = '';
            this.add.gender = '';
            this.add.birthdate = '';
            this.add.loksabha = '';
            this.add.vidhansabha = '';
            this.add.companyname = '';
            this.add.designation = '';
            this.add.votercreateimg = null;
            this.add.fulladdress = '';
            this.add.pincode = '';
            this.add.landmark = '';
            this.add.city = '';
            this.add.area = '';
            this.add.voterowner = '';
            this.add.bloodgroup = '';
            this.add.profession = '';
            this.add.tagname = null;
            this.createpreviewurl = null;
            this.showloadervoterregmodal = false;
            this.add.primobile = mob;
            this.mobile_number = mob;
        },
        voterRegistrationModalClose() {
            this.createvoterdetailstatus = false;
            setTimeout(() => {
                this.v$.$reset();
            }, 0);
            this.addvoteraadharloader = false;
            this.addvotercardloader = false;
            this.addvoterregloader = false;
            this.adduserregloader = false;
            this.voterAddStepAadharStatus = false;
            this.voterAddStepVoterStatus = false;
            this.voterAddStepFirstStatus = false;
            this.voterAddStepSecondStatus = false;
            this.voterAddStepThirdStatus = false;
            this.expandedKeys = {};
        },
        addVoterCardDetailbtn() {
            // 
            // this.voterAddStepVoterStatus = true;
            // this.showloadervotermodal = false;
            this.getvoterdetails();
            // this.addvotercardbuttonstatus = true;
        },
        getvoterdetails() {
            this.addvoteraadharloader = true;
            this.showloadervotermodal = true;
            this.voterarraylength = '';
            let fields = {};
            fields["ak24"] = this.mobile_number;
            fields["ak1"] = this.votersid;
            fields["update"] = 0;
            this.ApiService.updateuservoterdetails(fields).then((items) => {
                if (items.success === true) {
                    if (items.is_exists == 1) {
                        this.voterAddStepAadharStatus = false;
                        this.voterAddStepVoterStatus = true;
                        this.showloader = false;
                        // this.voterinputdisplayflag = false;
                        this.showloadervotermodal = false;
                        this.voterdivdisplayflag = 1;
                        this.votergovdata = items.data;
                        this.voterarraylength = 1;
                        // this.confirm_update_popup_status = false;
                        // this.addvotercardloader = false;                        
                    } else {
                        this.voterAddStepAadharStatus = false;
                        this.voterAddStepVoterStatus = true;
                        this.voterdivdisplayflag = 0;
                        // this.alreadyExitVoterMessage = items.message;
                        this.showloadervotermodal = false;
                        // var errorMsg = items.message;
                        // this.$toast.open({
                        //     message: errorMsg,
                        //     type: "error",
                        //     duration: 3000,
                        //     position: "top-right",
                        // });
                        this.addvoteraadharloader = false;
                    }
                } else {
                    this.voterAddStepAadharStatus = false;
                    this.voterAddStepVoterStatus = true;
                    this.voterdivdisplayflag = 0;
                    // this.alreadyExitVoterMessage = items.message;
                    this.showloadervotermodal = false;
                    // var errorMsg = items.message;
                    // this.$toast.open({
                    //     message: errorMsg,
                    //     type: "error",
                    //     duration: 3000,
                    //     position: "top-right",
                    // });
                    this.addvoteraadharloader = false;
                }
            });
        },
        addFirstStepDetailbtn() {
            this.voterAddStepFirstStatus = true;
            this.voterAddStepVoterStatus = false;
        },


        closeSearchAlertModal() {
            this.usersearchalertmodals = false;
            this.searchmobile = '';
        },
        createUserModalOpen(e) {
            this.createvoterdetailstatus = true;
            this.voterAddStepAadharStatus = true;
            this.showloadervotermodal = true;
            this.voterAddStepVoterStatus = false;
            this.voterAddStepFirstStatus = false;
            this.voterAddStepTwoStatus = false;
            this.voterAddStepThrirdStatus = false;
            this.showloadervotermodal = true;
            this.createpreviewurl = null;
            this.exitpreviewurl = null;
            this.aadharcardnumber = '';
            this.aadharotp = '';
            this.voterexitid = '';
            this.addharexitid = '';
            this.voterexitstatus = '';
            this.createpreviewurl = null;
            this.votersid = e.ak1;
            this.mobile_number = e.ak24;
            this.first_name = e.ak7;
            this.voterexitid = e.ak35;
            this.addharexitid = e.ak36;
            this.voterexitstatus = e.is_exist;
            this.add.email = e.ak23;
            if (e.ak83 != null && e.ak83 != '') {
                this.add.designation = {
                    label: e.ak84,
                    value: e.ak83,
                };
            }
            if (e.ak29 != 1) {
                this.checkAddUserAuth();
            } else {
                this.checkAddVoterAuth();
            }
            this.add.landmark = e.ak59;
            if (e.ak20 != null && e.ak20 != '') {
                this.add.bloodgroup = {
                    label: e.ak21,
                    value: e.ak20,
                };
            }
            if (e.ak122 != null && e.ak122 != '') {
                this.add.tagname = {
                    label: e.ak122,
                    value: e.ak121,
                };
            }
            if (e.ak66 != null && e.ak66 != '') {
                this.add.profession = {
                    label: e.ak67,
                    value: e.ak66,
                };
            }
            if (e.ak36 != null || e.ak36 != '') {
                this.getaadhardetails();
                this.addvoteraadharbuttonstatus = false;
            }
            if (e.ak151 == 2) {
                this.add.uploadphototype = 2;
            } else {
                this.add.uploadphototype = 1;
            }
            this.aadharprofileimage = e.ak33;
            this.existsmanualphoto = e.ak90;
        },
        handleUploadVoterCreate() {
            var file = this.$refs.votercreateimg.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!file.size > 2048) {
                this.errorprocreateimage = 'File size cannot exceed more than 2MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.errorprocreateimage = 'Invalid file type';
            } else {
                this.errorprocreateimage = '';
                this.add.votercreateimg = this.$refs.votercreateimg.files[0];
                this.createpreviewurl = this.add.votercreateimg;
            }
        },
        getClientRoles(e) {
            this.ApiService.getclientroles(e).then((data) => {
                if (data.status == 200) {
                    this.userrole_list = data.data;
                }
            });
        },
        expandAll() {
            for (let node in this.nodes) {
                this.expandNode(this.nodes[node]);
            }
            this.expandedKeys = { ...this.expandedKeys };
        },

        collapseAll() {
            this.expandedKeys = {};
        },

        expandNode(node) {
            if (node.children && node.children.length) {
                this.expandedKeys[node.key] = true;
                for (let child of node.children) {
                    this.expandNode(child);
                }
            }
        },


        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY");
            } else {
                return 'N/A';
            }
        },
        redirecttouserlisting() {
            this.$router.push("/user/listing");
        },
        checkAddUserAuth() {
            if (this.voterexitstatus == 1 || (this.voterexitid == '' || this.voterexitid == null) && (this.addharexitid == '' || this.addharexitid == null)) {
                this.exitvotererrormsg = true;
            } else {
                this.exitvotererrormsg = false;
            }
        },
        checkAddVoterAuth() {
            this.exitvotererrormsg = false;
        },
        createVoterAddDesignation(event) {
            if (event && event.value) {
                if (Number.isInteger(event.value)) {
                    this.add.designation = { value: event.value, label: event.label };
                } else {
                    this.add.designation = { value: 0, label: event.label };
                }
            }
        },

        confirmAadharUpdateModalOpen(aadhar) {
            this.confirm_aadhar_update_popup_status = true;
            this.aadhar_number_fk = aadhar;
        },
        confirmAadharUpdateModalClose() {
            this.confirm_aadhar_update_popup_status = false;
        },
        updateAadharReloadPermission() {
            this.showaconfirmloaderbtn = true;
            this.confirm_aadhar_update_popup_status = false;
            let fields = {};
            fields["ak24"] = this.mobile_number;
            fields["aadhar_number"] = this.aadhar_number_fk;
            fields["ak1"] = this.votersid;
            fields["update"] = 1;
            this.showloaderaadharmodal = true;
            this.ApiService.generateaadharotp(fields).then((items) => {
                if (items.success === true) {
                    this.aadharcardnumber = this.aadhar_number_fk;
                    this.aadhardivdisplayflag = 0;
                    this.showupdateloader = false;
                    this.showaconfirmloaderbtn = false;
                    var successMsg = items.message;
                    this.$toast.open({
                        message: successMsg,
                        type: "success",
                        duration: 3000,
                        position: "top-right",
                    });
                    this.displayMobilePopUp = true;
                    this.startMobileTimer();
                    this.timerMobileStatus = true;
                    this.aadharotp = "";
                    this.mobileotperr = '';
                    this.showloader = false;
                    this.sendOTPMessage = items.message;
                    this.mobile_otp_status = 0;
                    this.showresentloader = false;
                    this.resentotpbtnstatus = false;
                    this.showloaderaadharmodal = false;
                } else {
                    this.resentotpbtnstatus = false;
                    var errorMsg = items.message;
                    this.$toast.open({
                        message: errorMsg,
                        type: "error",
                        duration: 3000,
                        position: "top-right",
                    });
                    this.showresentloader = false;
                    this.sendOTPMessage = items.message;
                    this.showaconfirmloaderbtn = false;
                    this.showloaderaadharmodal = false;
                    this.systemlimiterrormsg = items.message;
                }
            });
        },
        sendAadharOtp() {
            this.showloader = true;
            if (
                this.aadharcardnumber != "" &&
                this.aadharcardnumber != null &&
                this.aadharcardnumber.length == 12
            ) {
                let fields = {};
                fields["ak24"] = this.mobile_number;
                fields["aadhar_number"] = this.aadharcardnumber;
                fields["ak1"] = this.votersid;
                fields["update"] = 0;
                this.ApiService.addUserUsingAadhar(fields).then((items) => {
                    if (items.success === true) {
                        // is_exits == 1 message display 
                        // is_exits == 0 OTP Send
                        if (items.data.is_exits == 1) {
                            this.AadharTabDetails = items.data;
                            this.aadharprofileimage = items.data.ak33;
                            this.existsmanualphoto = items.data.ak90;
                            this.showloader = false;
                            this.alreadyExitMessage = items.message;
                            var errorMsg = items.message;
                            this.$toast.open({
                                message: errorMsg,
                                type: "error",
                                duration: 3000,
                                position: "top-right",
                            });
                        } else {
                            this.displayMobilePopUp = true;
                            this.startMobileTimer();
                            this.timerMobileStatus = true;
                            this.aadharotp = "";
                            this.showloader = false;
                            this.sendOTPMessage = items.message;
                            this.votersid = items.data.ak1;
                            this.$toast.open({
                                message: items.message,
                                type: "success",
                                duration: 3000,
                                position: "top-right",
                            });
                        }
                    } else {
                        errorMsg = items.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.showloader = false;
                        this.aadharcardnumber = '';
                        this.aadharotp = "";
                        this.timerMobileStatus = false;
                        this.systemlimiterrormsg = items.message;
                        this.sendOTPMessage = items.message;
                    }
                });
            } else {
                this.$toast.open({
                    message: "Please enter valid Aadhar No.",
                    type: "warning",
                    duration: 3000,
                    position: "top-right",
                });
            }
        },
        resendAadharOtp() {
            this.showresentloader = true;
            let fields = {};
            fields["ak24"] = this.mobile_number;
            fields["aadhar_number"] = this.aadharcardnumber;
            fields["ak1"] = this.votersid;
            fields["update"] = 0;
            fields["resend_otp"] = 1;
            this.ApiService.addUserUsingAadhar(fields).then((items) => {
                if (items.success === true) {
                    this.showupdateloader = false;
                    var successMsg = items.message;
                    this.$toast.open({
                        message: successMsg,
                        type: "success",
                        duration: 3000,
                        position: "top-right",
                    });
                    this.displayMobilePopUp = true;
                    this.startMobileTimer();
                    this.timerMobileStatus = true;
                    this.aadharotp = "";
                    this.mobileotperr = '';
                    this.showloader = false;
                    this.sendOTPMessage = items.message;
                    this.mobile_otp_status = 0;
                    this.showresentloader = false;
                    this.resentotpbtnstatus = false;
                } else {
                    this.resentotpbtnstatus = false;
                    var errorMsg = items.message;
                    this.$toast.open({
                        message: errorMsg,
                        type: "error",
                        duration: 3000,
                        position: "top-right",
                    });
                    this.showresentloader = false;
                    this.sendOTPMessage = items.message;
                    this.systemlimiterrormsg = items.message;
                }
            });
        },
        verifyAadharOtp() {
            this.showloader = true;
            let fields = {};
            fields["ak24"] = this.mobile_number;
            fields["ak1"] = this.votersid;
            fields["otp"] = this.aadharotp;
            fields["aadhar_number"] = this.aadharcardnumber;
            fields["update"] = 1;
            this.ApiService.getuseraadharcarddetails(fields).then((items) => {
                if (items.success === true) {
                    this.showloaderaadharmodal = false;
                    this.voterAddStepAadharStatus = true;
                    this.addvoteraadharbuttonstatus = false;
                    this.aadhardivdisplayflag = 1;
                    this.aadharcardnumber = '';
                    this.aadharerr = '';
                    this.sendOTPMessage = '';
                    this.displayMobilePopUp = false;
                    this.aadharotp = '';
                    this.timerMobileStatus = false;
                    this.timerMobileCount = 120;
                    this.alreadyExitMessage = '';
                    this.AadharTabDetails = items.data;
                    this.aadharprofileimage = items.data.ak33;
                    this.existsmanualphoto = items.data.ak90;
                    this.first_name = items.data.ak7;
                    this.showloader = false;
                    var successMsg = items.message;
                    this.$toast.open({
                        message: successMsg,
                        type: "success",
                        duration: 3000,
                        position: "top-right",
                    });
                    this.aadharupdatebtnflag = false;
                }
                else if (items.status == 500) {
                    this.mobile_otp_status = 1;
                    this.sendOTPMessage = '';
                } else {
                    this.showloader = false;
                    this.mobileotperr = items.message;
                    var errorMsg = items.message;
                    this.$toast.open({
                        message: errorMsg,
                        type: "error",
                        duration: 3000,
                        position: "top-right",
                    });
                    this.timerMobileStatus = false;
                    this.timerMobileCount = 0;
                    this.aadharotp = "";
                    this.showloader = false;
                    this.resentotpbtnstatus = true;
                    setTimeout(() => {
                        this.mobile_otp_status = 1;
                    }, 480000);
                    // 8 minutes set timeout
                    this.sendOTPMessage = '';
                }
            });
        },
        startMobileTimer() {
            this.timerMobileStatus = true;
            if (this.timerMobileCount > 0) {
                setTimeout(() => {
                    this.timerMobileCount--;
                    this.startMobileTimer();
                }, 1000);
                const minutes = Math.floor(this.timerMobileCount / 60);
                let seconds = this.timerMobileCount % 60;
                if (seconds < 10) {
                    this.timerMobileInterval = `0${minutes}:0${seconds}`;
                } else {
                    this.timerMobileInterval = `0${minutes}:${seconds}`;
                }
            } else {
                this.timerMobileCount = 120;
                this.timerMobileStatus = false;
                this.resentotpbtnstatus = true;
            }
        },
        confirmUpdateModalOpen(e) {
            this.updatevoterid = e.ak35;
            this.confirm_update_popup_status = true;
        },
        confirmUpdateModalClose() {
            this.confirm_update_popup_status = false;
        },
        fetchvoteriddetails() {
            this.showloader = true;
            let fields = {};
            fields["ak24"] = this.mobile_number;
            fields["ak1"] = this.votersid;
            fields["voter_id"] = this.votercardnumber;
            fields["update"] = 0;
            this.ApiService.updateuservoterdetails(fields).then((items) => {
                if (items.success === true) {
                    if (items.is_exists == 1) {
                        if (items.data.ak1 == '' || items.data.ak1 == null) {
                            this.alreadyExitVoterMessage = items.message;
                            this.showloader = false;
                            this.voterinputdisplayflag = true;
                            this.showloadervotermodal = false;
                            this.votergovconfirmdata = items.data;
                            this.voterupdatedisplayflag = true;
                            this.updateVoterBtnStatus = true;
                            this.showupdateloader = false;
                            this.$toast.open({
                                message: items.message,
                                type: "warning",
                                duration: 3000,
                                position: "top-right",
                            });
                        } else {
                            this.alreadyExitVoterMessage = items.message;
                            this.showloader = false;
                            this.voterinputdisplayflag = true;
                            this.$toast.open({
                                message: items.message,
                                type: "error",
                                duration: 3000,
                                position: "top-right",
                            });
                        }
                    } else {
                        this.showloader = false;
                        this.alreadyExitVoterMessage = items.message;
                        this.showloader = false;
                        this.voterinputdisplayflag = true;
                        this.showloadervotermodal = false;
                        this.votergovconfirmdata = items.data;
                        this.voterupdatedisplayflag = true;
                        this.updateVoterBtnStatus = true;
                        this.showupdateloader = false;
                        this.$toast.open({
                            message: items.message,
                            type: "warning",
                            duration: 3000,
                            position: "top-right",
                        });
                    }
                } else {
                    this.showloader = false;
                    this.alreadyExitVoterMessage = items.message;
                    this.$toast.open({
                        message: items.message,
                        type: "error",
                        duration: 3000,
                        position: "top-right",
                    });
                }
            });
        },
        updateVoterPermission() {
            this.showconfirmloaderbtn = true;
            let fields = {};
            fields["ak24"] = this.mobile_number;
            fields["ak1"] = this.votersid;
            fields["voter_id"] = this.updatevoterid;
            fields["update"] = 1;
            this.ApiService.updateuservoterdetails(fields).then((items) => {
                if (items.success === true) {
                    this.updatevoterid = '';
                    this.showloader = false;
                    this.votergovdata = items.data;
                    this.voterarraylength = 1;
                    this.showloadervotermodal = false;
                    this.voterdivdisplayflag = 1;
                    this.confirm_update_popup_status = false;
                    this.addvotercardloader = false;
                    var successMsg = items.message;
                    this.$toast.open({
                        message: successMsg,
                        type: "success",
                        duration: 3000,
                        position: "top-right",
                    });
                    this.showconfirmloaderbtn = false;
                    this.alreadyExitVoterMessage = '';
                    this.updateVoterBtnStatus = false;
                    this.voterupdatedisplayflag = false;
                    this.voterAddStepVoterStatus = true;
                    this.addvotercardbuttonstatus = false;
                    this.updatevoterid = "";
                    this.votergovconfirmdata = [];
                    this.showloader = false;
                } else {
                    this.alreadyExitVoterMessage = items.message;
                    var errorMsg = items.message;
                    this.$toast.open({
                        message: errorMsg,
                        type: "error",
                        duration: 3000,
                        position: "top-right",
                    });
                    this.showconfirmloaderbtn = false;
                }
            });
        },
        // Get Aadhar Detail Form Function start here
        // First Get Aadhar Details API call
        getaadhardetails() {
            this.aadharUpdateBtnDaysMsg = '';
            this.showloaderaadharmodal = true;
            this.ApiService.getaadhardetails({ ak1: this.votersid }).then((data) => {
                if (data.success == true) {
                    this.AadharTabDetails = data.data;
                    if (this.AadharTabDetails.ak36 != null && this.AadharTabDetails.ak36 != '') {
                        this.aadhardivdisplayflag = 1;
                        if (this.AadharTabDetails.update == 0) {
                            this.aadharupdatebtnflag = false;
                            if (this.AadharTabDetails.update_days != 30) {
                                this.aadharUpdateBtnDaysMsg = 'You can update Aadhar Card details after ' + (30 - this.AadharTabDetails.update_days) + ' Days';
                            }
                        } else {
                            this.aadharupdatebtnflag = true;
                            this.aadharUpdateBtnDaysMsg = '';
                        }
                    } else {
                        this.aadhardivdisplayflag = 0;
                    }
                    this.showloaderaadharmodal = false;
                } else {
                    this.showloaderaadharmodal = false;
                }
            });
        },
        checkAadharNumber() {
            if (this.alreadyExitMessage != '') {
                this.alreadyExitMessage = '';
            } else {
                return true;
            }
        },
        createVoterAddCompanyName(event) {
            if (event && event.value) {
                if (Number.isInteger(event.value)) {
                    this.add.companyname = { value: event.value, label: event.label };
                } else {
                    this.add.companyname = { value: 0, label: event.label };
                }
            }
        },
        clearall() {
            this.searchmobile = '',
                this.searchuniqueid = '',
                this.createuserloader = false;
            this.searchMobileResult = null;
            this.createvoterdetailstatus = false;
            this.showloadervoterregmodal = false;
            this.disabledinput = false;
            setTimeout(() => {
                this.v$.$reset();
            }, 0);
        },
        clearallvoterinput() {
            this.voterupdatedisplayflag = false;
            this.votergovconfirmdata = '';
            this.alreadyExitVoterMessage = '';
            this.votercardnumber = '';
            this.voteriderr = '';
        },
        maskedEmail(getvalue) {
            const [name, domain] = getvalue.split('@');
            const [firstDomain, sublastdomain, lastdomain] = domain.split('.');
            if(name.length == 1 && !lastdomain) {
                return `${name}@${new Array(firstDomain.length).join('*')}.${sublastdomain}`;
            } else if(name.length == 1 && lastdomain) {
                return `${name}@${new Array(firstDomain.length).join('*')}.${sublastdomain}.${lastdomain}`;
            } else {
                return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
            }
        },
        CheckAllowDeleteVoterAccess(e) {
            if (e == true) {
            this.ApiService.checkAllowToDelete({ ak1:this.votersid }).then((data) => {
                if (data.status == 200) {
                this.deletevotermessage = data.message;
                this.addallow.displaydeletevoterpermission = 0;
                this.addallow.displaydeletevoterpermission = false;
                } else {
                this.deletevotermessage = '';
                this.addallow.displaydeletevoterpermission = 1;
                this.addallow.displaydeletevoterpermission = true;
                }
            });
            } else if (e == false) {
                this.addallow.displaydeletevoterpermission = 0;
                this.addallow.displaydeletevoterpermission = false;
            }
        }
    },
};
</script>
<style scoped>
.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 512px;
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.voter-exit-box-outer {
    padding: 16px 16px 0;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.customer-label-group {
    margin-bottom: 16px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.modal-bulk-next-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
    width: 115px;
}

.modal-bulk-next-btn:focus {
    box-shadow: none;
}

.voter-alert-group {
    font-family: "AcuminPro-Regular";
    font-size: 16px;
    letter-spacing: 1px;
    color: #303336;
}

.alert-body-bg {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.voter-already-user-status {
    padding: 8px 16px;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
    margin-bottom: 15px;
    color: #63957d;
    font-weight: 700;
    font-family: "AcuminPro-SemiBold";
}

.divider-custom-list {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dashed #d4e4f3;
}

.aadhar-resent-otp-btn {
    letter-spacing: 0px;
    color: #3362db;
    font-size: 14px;
    font-family: "AcuminPro-Regular";
    padding: 0;
    text-decoration: none;
}

.aadhar-resent-otp-btn:focus-visible {
    outline: 0;
}

.aadhar-resent-otp-btn:focus {
    box-shadow: none;
}
</style>